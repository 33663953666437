import { default as _47home_47jin0516_47jin0516_46xsrv_46jp_47opt_47public_47src_47pages_47company_46vueMeta } from "/home/jin0516/jin0516.xsrv.jp/opt/public/src/pages/company.vue?macro=true";
import { default as _47home_47jin0516_47jin0516_46xsrv_46jp_47opt_47public_47src_47pages_47contact_46vueMeta } from "/home/jin0516/jin0516.xsrv.jp/opt/public/src/pages/contact.vue?macro=true";
import { default as _47home_47jin0516_47jin0516_46xsrv_46jp_47opt_47public_47src_47pages_47index_46vueMeta } from "/home/jin0516/jin0516.xsrv.jp/opt/public/src/pages/index.vue?macro=true";
import { default as _47home_47jin0516_47jin0516_46xsrv_46jp_47opt_47public_47src_47pages_47notice_47detail_46vueMeta } from "/home/jin0516/jin0516.xsrv.jp/opt/public/src/pages/notice/detail.vue?macro=true";
import { default as _47home_47jin0516_47jin0516_46xsrv_46jp_47opt_47public_47src_47pages_47notice_47index_46vueMeta } from "/home/jin0516/jin0516.xsrv.jp/opt/public/src/pages/notice/index.vue?macro=true";
import { default as _47home_47jin0516_47jin0516_46xsrv_46jp_47opt_47public_47src_47pages_47office_46vueMeta } from "/home/jin0516/jin0516.xsrv.jp/opt/public/src/pages/office.vue?macro=true";
import { default as _47home_47jin0516_47jin0516_46xsrv_46jp_47opt_47public_47src_47pages_47policy_46vueMeta } from "/home/jin0516/jin0516.xsrv.jp/opt/public/src/pages/policy.vue?macro=true";
import { default as _47home_47jin0516_47jin0516_46xsrv_46jp_47opt_47public_47src_47pages_47recruit_46vueMeta } from "/home/jin0516/jin0516.xsrv.jp/opt/public/src/pages/recruit.vue?macro=true";
import { default as _47home_47jin0516_47jin0516_46xsrv_46jp_47opt_47public_47src_47pages_47workDetail_46vueMeta } from "/home/jin0516/jin0516.xsrv.jp/opt/public/src/pages/workDetail.vue?macro=true";
import { default as _47home_47jin0516_47jin0516_46xsrv_46jp_47opt_47public_47src_47pages_47works_46vueMeta } from "/home/jin0516/jin0516.xsrv.jp/opt/public/src/pages/works.vue?macro=true";
export default [
  {
    name: _47home_47jin0516_47jin0516_46xsrv_46jp_47opt_47public_47src_47pages_47company_46vueMeta?.name ?? "company",
    path: _47home_47jin0516_47jin0516_46xsrv_46jp_47opt_47public_47src_47pages_47company_46vueMeta?.path ?? "/company",
    file: "/home/jin0516/jin0516.xsrv.jp/opt/public/src/pages/company.vue",
    children: [],
    meta: _47home_47jin0516_47jin0516_46xsrv_46jp_47opt_47public_47src_47pages_47company_46vueMeta,
    alias: _47home_47jin0516_47jin0516_46xsrv_46jp_47opt_47public_47src_47pages_47company_46vueMeta?.alias || [],
    redirect: _47home_47jin0516_47jin0516_46xsrv_46jp_47opt_47public_47src_47pages_47company_46vueMeta?.redirect || undefined,
    component: () => import("/home/jin0516/jin0516.xsrv.jp/opt/public/src/pages/company.vue").then(m => m.default || m)
  },
  {
    name: _47home_47jin0516_47jin0516_46xsrv_46jp_47opt_47public_47src_47pages_47contact_46vueMeta?.name ?? "contact",
    path: _47home_47jin0516_47jin0516_46xsrv_46jp_47opt_47public_47src_47pages_47contact_46vueMeta?.path ?? "/contact",
    file: "/home/jin0516/jin0516.xsrv.jp/opt/public/src/pages/contact.vue",
    children: [],
    meta: _47home_47jin0516_47jin0516_46xsrv_46jp_47opt_47public_47src_47pages_47contact_46vueMeta,
    alias: _47home_47jin0516_47jin0516_46xsrv_46jp_47opt_47public_47src_47pages_47contact_46vueMeta?.alias || [],
    redirect: _47home_47jin0516_47jin0516_46xsrv_46jp_47opt_47public_47src_47pages_47contact_46vueMeta?.redirect || undefined,
    component: () => import("/home/jin0516/jin0516.xsrv.jp/opt/public/src/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: _47home_47jin0516_47jin0516_46xsrv_46jp_47opt_47public_47src_47pages_47index_46vueMeta?.name ?? "index",
    path: _47home_47jin0516_47jin0516_46xsrv_46jp_47opt_47public_47src_47pages_47index_46vueMeta?.path ?? "/",
    file: "/home/jin0516/jin0516.xsrv.jp/opt/public/src/pages/index.vue",
    children: [],
    meta: _47home_47jin0516_47jin0516_46xsrv_46jp_47opt_47public_47src_47pages_47index_46vueMeta,
    alias: _47home_47jin0516_47jin0516_46xsrv_46jp_47opt_47public_47src_47pages_47index_46vueMeta?.alias || [],
    redirect: _47home_47jin0516_47jin0516_46xsrv_46jp_47opt_47public_47src_47pages_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/jin0516/jin0516.xsrv.jp/opt/public/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47jin0516_47jin0516_46xsrv_46jp_47opt_47public_47src_47pages_47notice_47detail_46vueMeta?.name ?? "notice-detail",
    path: _47home_47jin0516_47jin0516_46xsrv_46jp_47opt_47public_47src_47pages_47notice_47detail_46vueMeta?.path ?? "/notice/detail",
    file: "/home/jin0516/jin0516.xsrv.jp/opt/public/src/pages/notice/detail.vue",
    children: [],
    meta: _47home_47jin0516_47jin0516_46xsrv_46jp_47opt_47public_47src_47pages_47notice_47detail_46vueMeta,
    alias: _47home_47jin0516_47jin0516_46xsrv_46jp_47opt_47public_47src_47pages_47notice_47detail_46vueMeta?.alias || [],
    redirect: _47home_47jin0516_47jin0516_46xsrv_46jp_47opt_47public_47src_47pages_47notice_47detail_46vueMeta?.redirect || undefined,
    component: () => import("/home/jin0516/jin0516.xsrv.jp/opt/public/src/pages/notice/detail.vue").then(m => m.default || m)
  },
  {
    name: _47home_47jin0516_47jin0516_46xsrv_46jp_47opt_47public_47src_47pages_47notice_47index_46vueMeta?.name ?? "notice",
    path: _47home_47jin0516_47jin0516_46xsrv_46jp_47opt_47public_47src_47pages_47notice_47index_46vueMeta?.path ?? "/notice",
    file: "/home/jin0516/jin0516.xsrv.jp/opt/public/src/pages/notice/index.vue",
    children: [],
    meta: _47home_47jin0516_47jin0516_46xsrv_46jp_47opt_47public_47src_47pages_47notice_47index_46vueMeta,
    alias: _47home_47jin0516_47jin0516_46xsrv_46jp_47opt_47public_47src_47pages_47notice_47index_46vueMeta?.alias || [],
    redirect: _47home_47jin0516_47jin0516_46xsrv_46jp_47opt_47public_47src_47pages_47notice_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/jin0516/jin0516.xsrv.jp/opt/public/src/pages/notice/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47jin0516_47jin0516_46xsrv_46jp_47opt_47public_47src_47pages_47office_46vueMeta?.name ?? "office",
    path: _47home_47jin0516_47jin0516_46xsrv_46jp_47opt_47public_47src_47pages_47office_46vueMeta?.path ?? "/office",
    file: "/home/jin0516/jin0516.xsrv.jp/opt/public/src/pages/office.vue",
    children: [],
    meta: _47home_47jin0516_47jin0516_46xsrv_46jp_47opt_47public_47src_47pages_47office_46vueMeta,
    alias: _47home_47jin0516_47jin0516_46xsrv_46jp_47opt_47public_47src_47pages_47office_46vueMeta?.alias || [],
    redirect: _47home_47jin0516_47jin0516_46xsrv_46jp_47opt_47public_47src_47pages_47office_46vueMeta?.redirect || undefined,
    component: () => import("/home/jin0516/jin0516.xsrv.jp/opt/public/src/pages/office.vue").then(m => m.default || m)
  },
  {
    name: _47home_47jin0516_47jin0516_46xsrv_46jp_47opt_47public_47src_47pages_47policy_46vueMeta?.name ?? "policy",
    path: _47home_47jin0516_47jin0516_46xsrv_46jp_47opt_47public_47src_47pages_47policy_46vueMeta?.path ?? "/policy",
    file: "/home/jin0516/jin0516.xsrv.jp/opt/public/src/pages/policy.vue",
    children: [],
    meta: _47home_47jin0516_47jin0516_46xsrv_46jp_47opt_47public_47src_47pages_47policy_46vueMeta,
    alias: _47home_47jin0516_47jin0516_46xsrv_46jp_47opt_47public_47src_47pages_47policy_46vueMeta?.alias || [],
    redirect: _47home_47jin0516_47jin0516_46xsrv_46jp_47opt_47public_47src_47pages_47policy_46vueMeta?.redirect || undefined,
    component: () => import("/home/jin0516/jin0516.xsrv.jp/opt/public/src/pages/policy.vue").then(m => m.default || m)
  },
  {
    name: _47home_47jin0516_47jin0516_46xsrv_46jp_47opt_47public_47src_47pages_47recruit_46vueMeta?.name ?? "recruit",
    path: _47home_47jin0516_47jin0516_46xsrv_46jp_47opt_47public_47src_47pages_47recruit_46vueMeta?.path ?? "/recruit",
    file: "/home/jin0516/jin0516.xsrv.jp/opt/public/src/pages/recruit.vue",
    children: [],
    meta: _47home_47jin0516_47jin0516_46xsrv_46jp_47opt_47public_47src_47pages_47recruit_46vueMeta,
    alias: _47home_47jin0516_47jin0516_46xsrv_46jp_47opt_47public_47src_47pages_47recruit_46vueMeta?.alias || [],
    redirect: _47home_47jin0516_47jin0516_46xsrv_46jp_47opt_47public_47src_47pages_47recruit_46vueMeta?.redirect || undefined,
    component: () => import("/home/jin0516/jin0516.xsrv.jp/opt/public/src/pages/recruit.vue").then(m => m.default || m)
  },
  {
    name: _47home_47jin0516_47jin0516_46xsrv_46jp_47opt_47public_47src_47pages_47workDetail_46vueMeta?.name ?? "workDetail",
    path: _47home_47jin0516_47jin0516_46xsrv_46jp_47opt_47public_47src_47pages_47workDetail_46vueMeta?.path ?? "/workDetail",
    file: "/home/jin0516/jin0516.xsrv.jp/opt/public/src/pages/workDetail.vue",
    children: [],
    meta: _47home_47jin0516_47jin0516_46xsrv_46jp_47opt_47public_47src_47pages_47workDetail_46vueMeta,
    alias: _47home_47jin0516_47jin0516_46xsrv_46jp_47opt_47public_47src_47pages_47workDetail_46vueMeta?.alias || [],
    redirect: _47home_47jin0516_47jin0516_46xsrv_46jp_47opt_47public_47src_47pages_47workDetail_46vueMeta?.redirect || undefined,
    component: () => import("/home/jin0516/jin0516.xsrv.jp/opt/public/src/pages/workDetail.vue").then(m => m.default || m)
  },
  {
    name: _47home_47jin0516_47jin0516_46xsrv_46jp_47opt_47public_47src_47pages_47works_46vueMeta?.name ?? "works",
    path: _47home_47jin0516_47jin0516_46xsrv_46jp_47opt_47public_47src_47pages_47works_46vueMeta?.path ?? "/works",
    file: "/home/jin0516/jin0516.xsrv.jp/opt/public/src/pages/works.vue",
    children: [],
    meta: _47home_47jin0516_47jin0516_46xsrv_46jp_47opt_47public_47src_47pages_47works_46vueMeta,
    alias: _47home_47jin0516_47jin0516_46xsrv_46jp_47opt_47public_47src_47pages_47works_46vueMeta?.alias || [],
    redirect: _47home_47jin0516_47jin0516_46xsrv_46jp_47opt_47public_47src_47pages_47works_46vueMeta?.redirect || undefined,
    component: () => import("/home/jin0516/jin0516.xsrv.jp/opt/public/src/pages/works.vue").then(m => m.default || m)
  }
]